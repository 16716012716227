<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : " - " }}
        </template>
        <template slot="orderStatusValue" slot-scope="text, record">
          <span
            v-if="
              record.signStatusValue === '待签署' ||
                record.signStatusValue === '部分签署' ||
                text === '待审核'
            "
          >
            签约中
          </span>
          <span v-else>
            <span
              v-if="
                text === '待审核部审核' ||
                  text === '待稽查部审核' ||
                  text === '待财务初审' ||
                  text === '待财务复审'
              "
              class="status--orange"
            >
              {{ text }}
            </span>
            <span v-else>
              {{ text }}
            </span>
          </span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a
              v-if="
                (record.orderStatusValue === '待审核部审核' ||
                  record.orderStatusValue === '待稽查部审核' ||
                  record.orderStatusValue === '待财务初审' ||
                  record.orderStatusValue === '待财务复审') &&
                  personnelType.includes(record.currentApprovalPersonnelType)
              "
              @click="onExamine(record.orderSn)"
            >
              审核
            </a>
            <a v-else @click="onDetail(record.orderSn)">查看</a>
          </a-space>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import Breadcrumb from "@/components/Breadcrumb";
import { tsFormat } from "@/components/DateUtils";
import { fetchCorporationInfoByKeyword } from "@/services/OrderManageService";
import { fetchToPayExamineList } from "@/services/FinancialService";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "20%"
  },
  {
    title: "下单机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "出厂价总额（元）",
    dataIndex: "totalFactoryPrice",
    width: "8%"
  },
  {
    title: "订单总额（元）",
    dataIndex: "totalPrice",
    width: "8%"
  },
  {
    title: "订单类别",
    dataIndex: "orderTypeValue",
    width: "8%"
  },
  {
    title: "审核状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: "11%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "7%"
  }
];

export default {
  name: "RefundReview",
  mixins: [pageDetailMixins],
  components: {
    Breadcrumb
  },
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "审核状态",
          key: "approvalStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核",
              value: "TO_AUDIT_HQ_PAYMENT_REVIEW"
            },
            {
              label: "已通过",
              value: "PASSED_HQ_PAYMENT_REVIEW"
            },
            {
              label: "未通过",
              value: "NO_PASSED_HQ_PAYMENT_REVIEW"
            }
          ]
        },
        {
          label: "下单机构",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "订单类型",
          key: "orderType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "即时订单",
              value: "INSTANT"
            },
            {
              label: "远期订单",
              value: "FROWARD"
            }
          ]
        },
        {
          label: "创建时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {},
      tblData: [],
      tblColumns,
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      tsFormat,
      personnelType: []
    };
  },
  mounted() {
    if (localStorage.getItem("personnelType")) {
      this.personnelType = JSON.parse(localStorage.getItem("personnelType"));
    }
  },
  activated() {
    this.loadList();
  },
  methods: {
    // 搜索下单机构
    async onSearchCorporation(value) {
      const corporationList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationList.push({
            label: `${item.corporationName}(${item.distributorTypeValue})`,
            value: item.distributorId
          });
        });
      }
      return corporationList;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        orderSn: this.form.orderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        orderCategory: "RETURNED",
        orderType: this.form.orderType || "",
        approvalStatus: this.form.approvalStatus || "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchToPayExamineList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 审核
    onExamine(orderSn) {
      this.$router.push({
        name: "RefundReviewExamine",
        params: { orderSn: orderSn, type: "examine" }
      });
    },
    // 查看
    onDetail(orderSn) {
      this.$router.push({
        name: "RefundReviewDetail",
        params: { orderSn: orderSn, type: "detail" }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}
</style>
