var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',[_c('div',{staticClass:"nav"},[_c('Breadcrumb')],1),_c('section',{staticClass:"search__container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.onSearch}})],1),_c('section',{staticClass:"table__container"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"loading":_vm.loading,"pagination":_vm.pagination,"rowKey":"orderSn"},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"createTime",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.tsFormat(text) : " - ")+" ")]}},{key:"orderStatusValue",fn:function(text, record){return [(
            record.signStatusValue === '待签署' ||
              record.signStatusValue === '部分签署' ||
              text === '待审核'
          )?_c('span',[_vm._v(" 签约中 ")]):_c('span',[(
              text === '待审核部审核' ||
                text === '待稽查部审核' ||
                text === '待财务初审' ||
                text === '待财务复审'
            )?_c('span',{staticClass:"status--orange"},[_vm._v(" "+_vm._s(text)+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])])]}},{key:"action",fn:function(text, record){return [_c('a-space',[(
              (record.orderStatusValue === '待审核部审核' ||
                record.orderStatusValue === '待稽查部审核' ||
                record.orderStatusValue === '待财务初审' ||
                record.orderStatusValue === '待财务复审') &&
                _vm.personnelType.includes(record.currentApprovalPersonnelType)
            )?_c('a',{on:{"click":function($event){return _vm.onExamine(record.orderSn)}}},[_vm._v(" 审核 ")]):_c('a',{on:{"click":function($event){return _vm.onDetail(record.orderSn)}}},[_vm._v("查看")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }